<template>
    <div class="gk-select">
        <van-cell :title="label" :value="value" is-link @click="open" />
        <van-popup v-model:show="show" :close-on-click-overlay="false" position="bottom">
            <van-picker
                ref="pickerRef"
                :columns="columns"
                :columns-field-names="keyNames"
                item-height="0.44rem"
                show-toolbar
                @cancel="cancel"
                @confirm="confirm"
            />
        </van-popup>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    active: Object,
    label: String,
    value: String,
    columns: Array,
    keyNames: {
        type: Object,
        default: {
            id: "id",
            text: "text",
            children: "children",
        },
    },
});

const emit = defineEmits(["update:show", "update:active", "update:value", "confirm", "cancel"]);

onMounted(() => {
    emit("update:value", `请选择${props.label}`);
});

watch(
    () => props.active,
    (val) => {
        // value赋值
        if (val) {
            if (Array.isArray(val)) {
                let newText = "";
                val.forEach((item) => {
                    newText += item[props.keyNames.text];
                });
                emit("update:value", newText);
            } else {
                emit("update:value", val[props.keyNames.text]);
            }
        }
    },
    {
        deep: true,
    },
);

let pickerRef = ref();

// 打开picker
let open = async () => {
    await emit("update:show", true);
    // 数据回显
    if (props.active) {
        if (Array.isArray(props.active)) {
            let textArr = [];
            props.active.forEach((item) => {
                textArr.push(item[props.keyNames.text]);
            });
            pickerRef.value.setValues(textArr);
        } else {
            pickerRef.value.setValues([props.active[props.keyNames.text]]);
        }
    }
};

let close = () => emit("update:show", false);

let confirm = (val) => {
    emit("update:active", val);
    emit("confirm", val);
    close();
};

let cancel = () => {
    emit("cancel");
    close();
};
</script>