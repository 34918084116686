import client from "./module/client.js";
import company from "@/router/module/company";
import construction from "@/router/module/construction";
import patrol from "@/router/module/patrol";

export default [
    // 登录
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/Auth/AuthLogin.vue")
    },
    // 资产客户端
    ...client,
    //生成厂商端
    ...company,
    // 施工单位
    ...construction,
    // 巡更单位
    ...patrol
];
