export default [
    {
        path: "/company",
        redirect: "/company/home",
        name: "CompanyIndex",
        component: () => import("../../views/Company/CompanyIndex.vue"),
        children: [
            // 厂商首页
            {
                path: "/company/home",
                name: "CompanyHome",
                meta: {
                    tabbar: 0
                },
                component: () => import("../../views/Company/Home/CompanyHome.vue")
            },
            // 厂商工作台
            {
                path: "/company/workbench",
                name: "CompanyWorkbench",
                meta: {
                    tabbar: 1
                },
                component: () => import("../../views/Company/Workbench/CompanyWorkbench.vue")
            },
            // 厂商发货
            {
                path: "/company/goods/send/list",
                name: "CompanyGoodsSendList",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Company/Goods/Send/CompanyGoodsSendList.vue")
            },
            {
                path: "/company/goods/send/edit",
                name: "CompanyGoodsSendEdit",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Company/Goods/Send/CompanyGoodsSendEdit.vue")
            },
            {
                path: "/company/goods/send/detail/:id",
                name: "CompanyGoodsSendDetail",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Company/Goods/Send/CompanyGoodsSendDetail.vue")
            },
            // 个人中心
            {
                path: "/company/my",
                name: "CompanyMyHome",
                meta: {
                    tabbar: 2
                },
                component: () => import("../../views/Client/My/MyHome.vue")
            }
        ]
    }
];
