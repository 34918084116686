<template>
    <div class="gk-image-uploader">
        <van-uploader v-if="!image" :after-read="onUpload" :disabled="disabled" />
        <div v-else class="preview">
            <van-image :src="image" fit="cover" @click="onPreview"></van-image>
        </div>
    </div>
</template>

<script setup>
import { ImagePreview } from "vant";
import { UPLOAD_SINGLE_IMAGE } from "@/api/auth";
import useHandleImage from "@/hooks/useHandleImage";

let emit = defineEmits(["update:image", "upload"]);
let props = defineProps({
    image: String,
    disabled: {
        type: Boolean,
        default: false
    },
    closeable: {
        type: Boolean,
        default: true
    },
    previewable: {
        type: Boolean,
        default: true
    }
});

let { compressBase64, base64toFile } = useHandleImage();

// 上传
let onUpload = async (file) => {
    if (props.disabled) return false;
    if (file) {
        await compressBase64(file.content).then((newBase64) => {
            base64toFile(newBase64, file.file.name).then((newFile) => {
                let formData = new FormData();
                formData.append("file", newFile);
                UPLOAD_SINGLE_IMAGE(formData).then((res) => {
                    if (res.data.code === 200) {
                        emit("update:image", res.data.data.url);
                        emit("upload", res.data.data.url);
                    }
                });
            });
        });
    }
};

// 删除
let onDelete = () => {
    emit("update:image", null);
};

// 预览功能
let onPreview = () => {
    if (!props.previewable) return false;
    ImagePreview({
        images: [props.image],
        closeable: true
    });
};
</script>

<style lang="scss" scoped>
.gk-image-uploader {
    width: 100%;
    height: 100%;

    .van-uploader {
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .preview {
        position: relative;
        width: 100%;
        height: 100%;

        .delete-button {
            position: absolute;
            top: -9px;
            right: -9px;
            width: 18px;
            height: 18px;
            z-index: 2;
            font-weight: bold;
            color: #ff0000;
        }

        .van-image {
            display: block;
            width: 100%;
            height: 100%;

            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
