export default [
    {
        path: "/client",
        redirect: "/client/home",
        name: "ClientIndex",
        component: () => import("../../views/Client/ClientIndex.vue"),
        children: [
            // 客户首页
            {
                path: "/client/home",
                name: "ClientHome",
                meta: {
                    tabbar: 0
                },
                component: () => import("../../views/Client/Home/ClientHome.vue")
            },
            // 客户工作台
            {
                path: "/client/workbench",
                name: "ClientWorkbench",
                meta: {
                    tabbar: 1
                },
                component: () => import("../../views/Client/Workbench/ClientWorkbench.vue")
            },
            // 客户个人中心
            {
                path: "/client/my",
                name: "ClientMyHome",
                meta: {
                    tabbar: 2
                },
                component: () => import("../../views/Client/My/MyHome.vue")
            },
            // 个人中心-修改信息
            {
                path: "/client/my/edit/info",
                name: "EditInformation",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/My/EditInformation.vue")
            },
            // 客户个人中心-修改密码
            {
                path: "/client/my/edit/pass",
                name: "EditPassword",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/My/EditPassword.vue")
            },
            // 货物管理-待收货
            {
                path: "/client/goods/unreceived",
                name: "ClientGoodsUnreceived",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Goods/Receive/ClientGoodsUnreceived.vue")
            },
            // 货物管理-已收货
            {
                path: "/client/goods/received",
                name: "ClientGoodsReceived",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Goods/Receive/ClientGoodsReceived.vue")
            },
            // 货物管理-货物详情单
            {
                path: "/client/goods/receive_detail/:id",
                name: "ClientGoodsReceiveDetail",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Goods/Receive/ClientGoodsReceiveDetail.vue")
            },
            // 货物管理-安装管理-待安装货物
            {
                path: "/client/goods/uninstalled",
                name: "ClientGoodsUninstalled",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Goods/Install/ClientGoodsUninstalled.vue")
            },
            // 货物管理-安装管理-进行安装中货物
            {
                path: "/client/goods/installing",
                name: "ClientGoodsInstalling",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Goods/Install/ClientGoodsInstalling.vue")
            },
            // 货物管理-安装管理-已完成安装货物
            {
                path: "/client/goods/installed",
                name: "ClientGoodsInstalled",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Goods/Install/ClientGoodsInstalled.vue")
            },
            // 货物管理-安装管理-安装详情单
            {
                path: "/client/goods/install/detail/:id",
                name: "ClientGoodsInstallDetail",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Goods/Install/ClientGoodsInstallDetail")
            },
            // 报警管理-待处理
            {
                path: "/client/alarm/pending",
                name: "AlarmPending",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Alarm/AlarmPending.vue")
            },
            // 报警管理-已知晓
            {
                path: "/client/alarm/known",
                name: "AlarmKnown",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Alarm/AlarmKnown.vue")
            },
            // 报警管理-已处理
            {
                path: "/client/alarm/processed",
                name: "AlarmProcessed",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Alarm/AlarmProcessed.vue")
            },
            // 单位管理-添加单位
            {
                path: "/client/unit/add",
                name: "UnitAdd",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/UnitManagement/UnitAdd.vue")
            },
            // 单位管理-施工单位列表
            {
                path: "/client/unit/list/construction",
                name: "UnitListConstruction",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/UnitManagement/UnitListConstruction.vue")
            },
            // 单位管理-巡护单位列表
            {
                path: "/client/unit/list/patrol",
                name: "UnitListPatrol",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/UnitManagement/UnitListPatrol.vue")
            },
            // 售后管理-新增售后
            {
                path: "/client/aftersales/add",
                name: "ClientGoodsAfterSalesAdd",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Goods/AfterSales/AfterSalesAdd.vue")
            },
            // 售后管理-审批中
            {
                path: "/client/aftersales/waiting",
                name: "ClientGoodsAfterSalesWaiting",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Goods/AfterSales/AfterSalesWaiting.vue")
            },
            // 售后管理-已完成
            {
                path: "/client/aftersales/completed",
                name: "ClientGoodsAfterSalesCompleted",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Goods/AfterSales/AfterSalesCompleted.vue")
            },
            // 售后管理-查看详情
            {
                path: "/client/aftersales/detail/:id",
                name: "ClientGoodsAfterSalesDetail",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Goods/AfterSales/AfterSalesDetail.vue")
            },
            // 审批管理-待审批
            {
                path: "/client/approval/wait",
                name: "ApprovalManagementExpectation",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/ApprovalManagement/ApprovalManagementExpectation.vue")
            },
            // 审批管理-已通过
            {
                path: "/client/approval/pass",
                name: "ApprovalManagementPass",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/ApprovalManagement/ApprovalManagementPass.vue")
            },
            // 审批管理-未通过
            {
                path: "/client/approval/notpass",
                name: "ApprovalManagementDidNotPass",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/ApprovalManagement/ApprovalManagementDidNotPass.vue")
            },
            // 审批管理-详情
            {
                path: "/client/approval/detail/:id",
                name: "ApprovalManagementDetail",
                meta: {
                    tabbar: -1
                },

                component: () => import("../../views/Client/ApprovalManagement/ApprovalManagementDetail.vue")
            },
            // 巡更管理-新增巡更
            {
                path: "/client/patrol/add",
                name: "ClientPatrolAdd",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Patrol/PatrolAdd.vue")
            },
            // 巡更管理-任务列表
            {
                path: "/client/patrol/list",
                name: "ClientPatrolList",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Patrol/PatrolList.vue")
            },
            // 巡更管理-任务记录
            {
                path: "/client/patrol/recording",
                name: "ClientPatrolRecording",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Patrol/PatrolRecording.vue")
            },
            // 账号管理-新增
            {
                path: "/client/account/add",
                name: "ClientAccountAdd",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Account/AccountAdd.vue")
            },
            // 账号管理-用户列表
            {
                path: "/client/account/list",
                name: "ClientAccountList",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Client/Account/AccountList.vue")
            }
        ]
    }
];
