<template>
    <div class="gk-date-select">
        <van-cell :title="label" :value="value" is-link @click="open" />
        <van-popup v-model:show="show" :close-on-click-overlay="false" position="bottom">
            <van-datetime-picker
                v-model="currentDate"
                :max-date="maxDate"
                :min-date="minDate"
                item-height="0.44rem"
                show-toolbar
                title="选择日期"
                :type="type"
                @cancel="cancel"
                @confirm="confirm"
            />
        </van-popup>
    </div>
</template>

<script>
import { ref, watch } from "vue";
import useFormatDateTime from "@/hooks/useFormatDateTime";

export default {
    name: "GkDateSelect",
    emits: ["update:show", "update:active", "update:value", "confirm", "cancel"],
    props: {
        show: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: "date"
        },
        valueType: {
            type: String,
            default: "datetime"
        },
        minDate: {
            type: Date,
            default: new Date(1900, 0, 1)
        },
        maxDate: {
            type: Date,
            default: new Date()
        },
        active: null,
        label: String,
        value: String
    },
    setup(props, context) {
        let { formatDateTime } = useFormatDateTime();

        watch(
            () => props.active,
            (val) => {
                if (val) {
                    context.emit("update:value", val);
                }
            },
            {
                deep: true
            }
        );

        let currentDate = ref(new Date());

        let open = async () => {
            await context.emit("update:show", true);
        };

        let close = () => {
            context.emit("update:show", false);
        };

        let confirm = (val) => {
            context.emit("update:active", formatDateTime(val, props.valueType));
            context.emit("confirm", formatDateTime(val, props.valueType));
            close();
        };

        let cancel = () => {
            context.emit("cancel");
            close();
        };

        return {
            currentDate,
            open,
            close,
            confirm,
            cancel
        };
    }
};
</script>
