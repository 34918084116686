export default [
    {
        path: "/patrol",
        redirect: "/patrol/list",
        name: "PatrolIndex",
        component: () => import("../../views/Patrol/PatrolIndex.vue"),
        children: [
            // 巡更列表
            {
                path: "/patrol/list",
                name: "PatrolList.vue",
                meta: {
                    tabbar: 0
                },
                component: () => import("../../views/Patrol/Patrol/PatrolList.vue")
            },
            // 巡更打卡
            {
                path: "/patrol/punch/:id",
                name: "PatrolPunch",
                meta: {
                    tabbar: 0
                },
                component: () => import("../../views/Patrol/Patrol/PatrolPunch.vue")
            },
            // 个人中心
            {
                path: "/patrol/my",
                name: "PatrolMyHome",
                meta: {
                    tabbar: 1
                },
                component: () => import("../../views/Client/My/MyHome.vue")
            }
        ]
    }
];
