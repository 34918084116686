import axios from "axios";
import $router from "@/router/index.js";
import { Toast } from "vant";

// 全局loading
let loading;
let openLoading = () => {
    loading = Toast.loading({
        message: "加载中",
        duration: 0,
        forbidClick: true,
    });
};
let clearLoading = () => {
    loading.clear();
};

const service = axios.create({
    timeout: 10000,
    withCredentials: true,
    baseURL: process.env.VUE_APP_BASE_URL,
});

// axios拦截器
service.interceptors.request.use(
    (config) => {
        openLoading();
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

service.interceptors.response.use(
    (response) => {
        clearLoading();
        if (response.data.code !== 200) {
            Toast({
                type: "fail",
                message: response.data.message,
            });
        }

        return response;
    },
    (error) => {
        clearLoading();
        if (error.response) {
            if (error.response.status === 401) {
                Toast({
                    type: "fail",
                    message: "登录状态失效，请重新登录！",
                });
                $router.push({ name: "Login" });
            }
            return Promise.reject(error.response);
        }
    },
);

// 请求封装
export function httpPost(url, params, config) {
    return service({
        method: "post",
        url: url,
        data: params,
        ...config,
    });
}

export function httpGet(url, params, config) {
    return service({
        method: "get",
        url: url,
        params: params,
        ...config,
    });
}

export function httpPut(url, params, config) {
    return service({
        method: "put",
        url: url,
        data: params,
        ...config,
    });
}

export function httpDelete(url, params, config) {
    return service({
        method: "delete",
        url: url,
        data: params,
        ...config,
    });
}
