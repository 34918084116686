export default function () {
    // 压缩图片
    let compressBase64 = (base64, width) => {
        return new Promise((resolve) => {
            // 新建一个img标签（不嵌入DOM节点，仅做canvas操作)
            let image = new Image()
            image.src = base64

            // 图片加载完毕后再通过canvas压缩图片（图片还没加载完就压缩，有可能得到一张全黑的图片）
            image.onload = function () {
                let canvas = document.createElement("canvas")
                let context = canvas.getContext("2d")

                // 获取图片原始宽高
                let canvasWidth = image.width;
                let canvasHeight = image.height;

                // 获取图片原始宽高比
                let sizeRatio = canvasWidth / canvasHeight;

                // 固定宽，按原始宽高比压缩图片
                let newWidth
                width ? newWidth = width : newWidth = 1024
                if (canvasWidth > newWidth) {
                    canvasWidth = newWidth;
                    canvasHeight = Math.floor(canvasWidth / sizeRatio);
                }

                // 压缩后设置宽高
                canvas.width = canvasWidth
                canvas.height = canvasHeight

                // 使用drawImage重新设置img标签中的图片大小，实现压缩。
                context.drawImage(image, 0, 0, canvasWidth, canvasHeight)

                // 使用toDataURL将canvas上的图片转换为base64格式
                resolve(canvas.toDataURL("image/jpeg"))
            }
        })
    }

    // 图片url转base64
    let urlToBase64 = (url) => {
        return new Promise((resolve, reject) => {
            let image = new Image();
            image.setAttribute("crossOrigin", "Anonymous");
            image.src = url;

            image.onload = function () {
                let canvas = document.createElement("canvas")
                let context = canvas.getContext("2d")
                canvas.width = image.naturalWidth;
                canvas.height = image.naturalHeight;
                context.drawImage(image, 0, 0);
                resolve(canvas.toDataURL("image/png"));
            };

            image.onerror = () => {
                reject("转换失败！");
            };
        });
    }

    // base64转file
    let base64toFile = (base64, fileName) => {
        return new Promise((resolve) => {
            let arr = base64.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            let blobData = new Blob([u8arr], {type: mime});
            blobData.lastModifiedDate = new Date();
            blobData.name = fileName;
            resolve(blobData)
        })
    }

    return {
        compressBase64,
        urlToBase64,
        base64toFile
    }
}
