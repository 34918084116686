export default [
    {
        path: "/construction",
        redirect: "/construction/install/list",
        name: "ConstructionIndex",
        component: () => import("../../views/Construction/ConstructionIndex.vue"),
        children: [
            // 安装
            {
                path: "/construction/install/list",
                name: "ConstructionInstallList.vue",
                meta: {
                    tabbar: 0
                },
                component: () => import("../../views/Construction/Install/ConstructionInstallList.vue")
            },
            {
                path: "/construction/goods/install/detail/:id",
                name: "ConstructionGoodsInstallDetail",
                meta: {
                    tabbar: -1
                },
                component: () => import("../../views/Construction/Install/ConstructionGoodsInstallDetail.vue")
            },
            // 个人中心
            {
                path: "/construction/my",
                name: "ConstructionMyHome",
                meta: {
                    tabbar: 1
                },
                component: () => import("../../views/Client/My/MyHome.vue")
            }
        ]
    }
];
