import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store/index.js";
import "./utils/rem.js";

// vant-ui
import vant from "vant";
import "vant/lib/index.css";

// geek-ui
import GkSelect from "./components/GeekQoo/GkSelect/index.vue";
import GkDateSelect from "./components/GeekQoo/GkDateSelect/index.vue";
import GkImageUploader from "./components/GeekQoo/GkImageUploader";

const app = createApp(App);

app.use(vant)
    .use(router)
    .use(store)
    .component("GkSelect", GkSelect)
    .component("GkDateSelect", GkDateSelect)
    .component("GkImageUploader", GkImageUploader)
    .mount("#app");
